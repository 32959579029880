body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gallery-modal {
  background: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100vh;
  box-shadow: 0 5px 10px 2px rgba(195, 192, 192, 0.5);
  text-align: center;
  z-index: 66;
}

#modalGalleryMobile {
  height: 100vh;
  display: grid;
  top: 0;
  left: 0;
  z-index: 4;
  position: fixed;
  overflow: auto;
}

@font-face {
  font-family: 'Open Sans';
  src: url('static/assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('static/assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans Light';
  src: url('static/assets/fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('static/assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}