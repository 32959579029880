@import "~react-image-gallery/styles/css/image-gallery.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.modal-info-card .modal-content {
  background-color: transparent;
  padding: 0;
  border: none;
  bottom: -166px;
}

.modal-info-card .modal-content .modal-body {
  padding: 0;
}

.modal-info-card .float-info {
  width: 100% !important;
  height: 392px !important;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  top: -361px !important;
  left: 2% !important;
  font-family: sans-serif;
  text-align: left;
  padding: 32px 24px;
  z-index: 0;
}

.modal-info-card .float-info p {
  margin-bottom: 0;
}

.modal-info-card .float-info .header-info {
  padding-bottom: 24px;
}
.modal-info-card .float-info .title-post {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  color: #2e2933;
}

.gm-style-pbc {
  z-index: 4 !important;
}

.modal-info-card .float-info .paragraph-info {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* or 147% */

  color: #4d4a4f;
}
.modal-info-card .icon-close {
  position: absolute;
  z-index: 1;
  margin-top: 8px;
  right: 19px;
  padding-right: 3px;
}
.modal-info-card .float-info .legend {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #6d6970;
}
.modal-info-card .float-info .highlighted {
  position: relative;
  color: #320066;
  display: flex;
  padding: 12px 4px 0 4px;
  margin-bottom: 10px;
}


.modal-info-card .float-info .highlighted .copy-action {
  padding-right: 8x;
}

.modal-info-card .float-info .highlighted:hover {
  cursor: pointer;
}

.modal-info-card .float-info .highlighted p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #320066;
}

.modal-info-card .float-info .highlighted:hover::after {
  content: "";
  position: absolute;
  background-color: #f2e6ff;
  border-color: #f2e6ff;
  border-style: solid;
  border-width: 5.5px 0;
  height: 28px;
  bottom: 0;
  left: 0;
  margin-top: -1em;
  width: 229px;
  border-radius: 1px;
  z-index: -1;
}

.modal-info-card .float-info .highlighted::after {
  content: "";
  position: absolute;
  border-color: #f2e6ff;
  border-style: solid;
  border-width: 5.5px 0;
  height: 11px;
  bottom: 0;
  left: 0;
  margin-top: -1em;
  width: 229px;
  border-radius: 1px;
  z-index: -1;
}
